<template>
  <wp-items-list
    content-type="ask-dwg-articles"
    content-type-singular="ask-dwg-article"
    content-name="Ask DWG articles"
    content-name-singular="Ask DWG article"
  />
</template>

<script>
import WpItemsList from '@/views/wp-item/wp-items-list/WpItemsList.vue'

export default {
  components: {
    WpItemsList,
  },
}
</script>

<style scoped>

</style>
